import { RouterLink } from 'vue-router'

export type RouterLinkOptions = {
  external?: boolean
  class?: string
  href?: string
}

const defaultOptions: RouterLinkOptions = {
  external: false,
  class: '',
}

export default (text: string | VNode, to: string, options = defaultOptions) => () => {
  const href = options.href ?? to
  if (options.external) {
    const target = options.external ? { target: '_blank', rel: 'noopener' } : {}
    return h('a', { href, class: options.class, ...target }, text)
  }
  return h(RouterLink, { to: href, class: options.class }, () => text)
}
